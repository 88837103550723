export const GlobalContentQueryGQL = (id: string, locale: string): string => `
query {
  globalContent(id:"${id}", locale:"${locale}") {
    itemsCollection {
      items {
        internalName
        key
        value
      }
    }
  }
}`;
