import { InterstitialPageQueryGQL } from '../../queries/contentful/interstitial-page-query.gql';
import { useContentfulQuery } from '../../queries/contentful/use-contentful-query';

type WithInterstitialPageContentProps = {
  contentId: string;
  locale: string;
};

type HOCComponentProps<TProps> = Omit<
  TProps & WithInterstitialPageContentProps,
  'content' | 'isLoading'
>;

export function WithInterstitialPageContent<TProps>(
  Component: React.ComponentType<TProps>,
): (props: HOCComponentProps<TProps>) => JSX.Element {
  const ComponentRender = (
    props: TProps & WithInterstitialPageContentProps,
  ): JSX.Element => {
    const { contentId, locale } = props;

    const { data, isLoading } = useContentfulQuery(
      InterstitialPageQueryGQL(contentId, locale),
    );

    return (
      <Component
        {...props}
        content={data?.interstitialPage}
        isLoading={isLoading}
      />
    );
  };

  ComponentRender.displayName = `WithInterstitialPageContent(${Component.displayName})`;

  return ComponentRender;
}
