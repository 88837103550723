import { contentfulLocale } from '@summer-health/types/locale';
import { InterstitialPage } from '@summer-health/ui/care-interstitial';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { siteDescription, siteTitle } from '../../utils/site-meta-copy';
import { WithInterstitialPageContent } from '../hoc/with-interstitial-page-content';

const InterstitialPageWithContent =
  WithInterstitialPageContent(InterstitialPage);

export function WelcomePage(): JSX.Element {
  const { i18n } = useTranslation();

  return (
    <>
      <Head>
        <meta name="description" content={siteDescription} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://care.summerhealth.com" />
        <meta name="twitter:title" content={siteTitle} />
        <meta name="twitter:description" content={siteDescription} />
        <meta name="twitter:image" content="/site-icon-192x192.png" />
        <meta name="twitter:creator" content="@joinsummer" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={siteDescription} />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:url" content="https://care.summerhealth.com" />
        <meta property="og:image" content="/site-icon-512x512.png" />
        <link
          rel="preload"
          href="https://images.ctfassets.net/st5h503u1hku/11RFQe5JDVh2AOjOV95qXd/a79d1b8e7dce90de1be0733cac37c15c/ellen-final.webp"
          as="image"
        />
        <link
          rel="prefetch"
          href="https://images.ctfassets.net/st5h503u1hku/GCujhG2OwxsCh2PvsQYzK/b2f70455ee3d712a7302c372925bdbfb/ali-final.webp"
          as="image"
        />
        <link
          rel="prefetch"
          href="https://images.ctfassets.net/st5h503u1hku/1xdYWqDJLZARSbp0Rt8OF4/fd09bfdb8f9297f8ce8957aa88fd92d3/complete-final.webp"
          as="image"
        />
      </Head>
      <InterstitialPageWithContent
        contentId="sFlODfF8PpBwnLUAvqfGE"
        locale={contentfulLocale(i18n.language)}
      />
    </>
  );
}
