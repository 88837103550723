export enum Locale {
  EN_US = 'en-US',
  ES_US = 'es-US',
}

export enum Language {
  English = 'en',
  Spanish = 'es',
}

export const LanguageLabel = {
  [Language.English]: 'English',
  [Language.Spanish]: 'Spanish',
};

export const LocaleMap = {
  en: Locale.EN_US,
  es: Locale.ES_US,
};

export const contentfulLocale = (language: string): Locale =>
  LocaleMap[language as Language] || Locale.EN_US;
